import { Grid } from '@mui/material'
import { styled } from '@mui/system'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'
import { Txt } from '../components/Txt'

export const Head = () => (
    <SEO
        title="株式会社ミスプラチナ 会社概要"
        description="株式会社ミスプラチナは低価格・高品質の結婚指輪、婚約指輪を中心に通販、ショールームでのご試着でご利用頂いております。"
        page="company"
    />
)

const CompanyPage: React.FC = () => {
    return (
        <Layout breadcrumbs={[{ name: '会社概要' }]}>
            <Grid container spacing={0} component="main">
                <Grid item sm={2} />
                <Grid item sm={8} xs={12}>
                    <StaticImage
                        src="../images/company/01.jpg"
                        alt=""
                        width={640}
                    />
                    <Title>
                        <Txt>株式会社ミスプラチナ</Txt>
                    </Title>
                    <Table>
                        <Row>
                            <H>
                                <Txt bold size="s">
                                    会社名
                                </Txt>
                            </H>
                            <D>
                                <Txt size="s">株式会社ミスプラチナ</Txt>
                            </D>
                        </Row>
                        <Row>
                            <H>
                                <Txt bold size="s">
                                    事業内容
                                </Txt>
                            </H>
                            <D>
                                <Txt size="s">
                                    ジュエリー販売 / ブライダル広告
                                </Txt>
                            </D>
                        </Row>
                        <Row>
                            <H>
                                <Txt bold size="s">
                                    所在地
                                </Txt>
                            </H>
                            <D>
                                <Txt size="s">東京都渋谷区神宮前3−16−16−1F</Txt>
                            </D>
                        </Row>
                        <Row>
                            <H>
                                <Txt bold size="s">
                                    電話番号
                                </Txt>
                            </H>
                            <D>
                                <Txt size="s">03-6419-7057</Txt>
                            </D>
                        </Row>
                        <Row>
                            <H>
                                <Txt bold size="s">
                                    メールアドレス
                                </Txt>
                            </H>
                            <D>
                                <Txt size="s">info@missplatinum.net</Txt>
                            </D>
                        </Row>
                    </Table>
                </Grid>
            </Grid>
        </Layout>
    )
}

const Title = styled('h1')({
    display: 'flex',
    margin: 0,
    padding: '12px',
})
const Table = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '340px',
    margin: '8px auto 48px',
    border: '1px solid #aaaaaa',
    borderBottom: 'none',
})
const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #aaaaaa',
})
const H = styled('div')({
    width: '140px',
    padding: '8px',
    borderRight: '2px solid #aaaaaa',
    backgroundColor: '#f5f5f5',
})
const D = styled('div')({
    flex: 1,
    padding: '8px',
})

export default CompanyPage
